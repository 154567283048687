export default {
  "404": {
    "back-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home page"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested page could not be found!"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])}
  },
  "account": {
    "last-logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last logins"])},
    "last-logins-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "last-logins-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "links": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
      "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete link"])},
      "unlink-confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this link?"])},
      "unlink-confirm-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete link"])}
    },
    "my-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Stats"])},
    "player-verify": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link now"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "no-player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet linked your account with your Minecraft account"])},
      "player-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player name"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Minecraft, enter the command <code class='color-primary'>/account-verify</code> to generate a verification code. Then enter your Minecraft name and the generated code here."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Minecraft Account"])}
    },
    "rank-expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank valid until"])},
    "register-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "state": {
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect login"])},
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logged in"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])}
  },
  "acp": {
    "licenses": {
      "AIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flightlicense"])},
      "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivinglicense"])},
      "WEAPON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weaponlicense"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add License"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])}
    }
  },
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "admin": {
    "chats": {
      "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close Date"])},
      "closedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closer"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "selectChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Char"])},
      "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sender"])}
    },
    "download": {
      "delete-download-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this download?"])},
      "delete-download-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete download"])},
      "delete-section-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this download section? This will also delete all downloads!"])},
      "delete-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download section delete"])}
    },
    "permissions": {
      "addPermSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission has been added successfully"])},
      "groups": {
        "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new group"])},
        "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the group?"])},
        "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
        "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])}
      },
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Permission"])},
      "ranks": {
        "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit rank"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
        "mcPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft - Prefix"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranks"])},
        "titlesingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
    },
    "player": {
      "char-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character Possibility"])},
      "characters": {
        "headers": {
          "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
          "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
          "id-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])},
          "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Played"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "player-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
          "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working?"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])}
      },
      "mod-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First use"])},
      "mod-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ModID"])},
      "mod-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last use"])},
      "mod-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod Name"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
      "warnings": {
        "headers": {
          "proof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof"])},
          "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
          "warned-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warned at"])},
          "warner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warner"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnings"])}
      }
    },
    "players": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player name"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
      "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])}
    },
    "pre-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - "])}
  },
  "api-errors": {
    "AIRPORT_BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been banned at the Airport, please contact our Support on Teamspeak"])},
    "CHAT_ALREADY_OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an open Chat of this type!"])},
    "CHAT_TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently banned from opening this type of chat!"])},
    "CONFIRMATION_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This code has already expired. You will receive a new code."])},
    "CONFIRMATION_TOO_MUCH_FAILURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many failed attempts. You will receive a new code."])},
    "CONFIRMATION_WRONG_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid code"])},
    "EMAIL_ALREADY_REGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address is already in use"])},
    "EMAIL_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
    "INVALID_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid code"])},
    "INVALID_LICENSE_FOR_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid License for Licensetype"])},
    "INVALID_LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password do not match"])},
    "MISSING_FIELD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all requierd fields"])},
    "NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No authorization"])},
    "PLAYER_HAS_ALREADY_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The player already has this permission (possibly through a group or rank)"])},
    "QUIZ_BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have failed the quiz, you do not need to apply to be unbanned for this"])}
  },
  "auth": {
    "accept-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the <a class='color-primary' target='_blank' href='/terms'>GTCs</a>"])},
    "already-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already an Account?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete registration"])},
    "confirm-already-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address has not been verified yet. Enter the code to complete the registration process"])},
    "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should receive an email with a confirmation code as soon as possible. Enter the code to complete the registration. (Don't forget to check your spam folder)."])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password forgotten?"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
    "labels": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
      "password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account yet?"])},
    "resend-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email again"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "reset-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should receive an email with a code in no time. Enter the code to set a new password. (Don't forget to check your spam folder)."])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  },
  "chat": {
    "APPLY_BUILDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Builder Application"])},
    "APPLY_CONTENT_SOCIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content (Section SocialMedia)"])},
    "APPLY_CONTENT_TEXTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content (Section Textures)"])},
    "APPLY_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dev apply"])},
    "APPLY_SUPPORTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporter Application"])},
    "CHAR_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Char deletion"])},
    "SECCHAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Char"])},
    "UNBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unban"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open new Chat"])},
    "newOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new Chat has been opened! 🎉"])},
    "newOpenInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further information await you after opening the chat"])},
    "noChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any Chats!"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "premessage": {
      "autoparameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic parameter"])},
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check message"])},
      "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messageparameter"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Member"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagetext"])}
    },
    "premessages": {
      "SECCHAR_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secchar accept"])},
      "SECCHAR_DENY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secchar deny"])},
      "SECCHAR_RECIEVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secchar recieved"])},
      "UNBAN_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept unban"])},
      "UNBAN_DENY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny unban"])},
      "UNBAN_RECIEVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban recieved"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
    },
    "reasons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom reason"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "secchar": {
        "notEnoughEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient reason for entry"])},
        "recentBan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent ban from our server"])},
        "storyUndetailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History too undetailed"])},
        "storyfoggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconsistency within the story"])},
        "toLessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too little information about your character"])}
      },
      "unban": {
        "notEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient application for removal"])},
        "notRPfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We see no reason how you could promote our RP"])},
        "toLessTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too little time between ban and application"])}
      }
    },
    "side": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "precreated": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Finished messages"]), _normalize(["Finished message"])])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])}
    },
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Application"]), _normalize(["Applications"]), _normalize(["Your Applications"])])},
    "welcome": {
      "APPLY_BUILDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your name and age</li> <li>Why StateMC</li> <li>Your experience as a builder</li> <li>What are you good at building</li> <li>Your experience with modblocks</li> <li>Your experience with RP and StateMC</li> </ul>"])},
      "APPLY_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your name and age</li> <li>Why StateMC</li> <li>How you want to help us</li> <li>Your knowledge</li> <li>As well as your skills in dealing with Git and GitLab, for example</li> </ul>"])},
      "APPLY_SUPPORTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your name and age</li> <li>Why StateMC</li> <li>Your experience as a supporter</li> <li>Your experience with RP and StateMC</li> </ul>"])},
      "CHAR_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>The reason for the character deletion</li> <li>>our plan for your future on StateMC</li> </ul>"])},
      "SECCHAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your playing time</li> <li>Your character's reason for entry</li> <li>Your character's history</li> <li>Your character's attributes</li> </ul>"])},
      "UNBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your reason for getting banned</li> <li>Your Ban duration</li> <li>The reason why we should unban you</li> </ul>"])}
    }
  },
  "cookies": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies and similar technologies on our website and process personal data about you, such as your IP address. We also share this data with third parties. The data processing may be done with your consent or on the basis of a legitimate interest."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses cookies"])}
  },
  "dialog": {
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "downloads": {
    "download-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "open-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "games": {
    "freeroam": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Freeroam, each player has the freedom to choose his own activities and goals. You can compete with other players in car races, shoot wildly around the area, farm, or participate in the regular, exciting challenges. There are no fixed rules or constraints and the possibilities are endless."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])}
    },
    "gungame": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame is about improving yourself in a competition with other players by killing enemies. After each successful kill you get a new weapon, which leads to increasingly greater challenges. The objective of the game is to advance to the highest level by mastering these challenges and make the final kill with a baseball bat."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])}
    },
    "roleplay": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay is about putting yourself in the role of a fictional character and simulating their life. This can be different types of characters such as a gangster, police officer or businessman. The goal is to create entertaining and exciting situations between the different characters."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])}
    },
    "ttt": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In TTT, players are divided into three different groups: Traitors, Detectives and Innocents. The Innocents' task is to track down and eliminate the Traitors, while the Detectives assist them with their special abilities. On the other hand, the Traitors have the goal of killing all Innocents and Detectives as unnoticed as possible before their true identity is revealed."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])}
    }
  },
  "home": {
    "card1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC differentiates itself from vanilla servers by using innovative mods to enhance the gameplay experience and provide unique experiences."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About StateMC"])}
    },
    "card2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our specially developed voice plugin for Teamspeak enables excellent audio quality in the gaming experience and is used in Roleplay, Freeroam and TTT to create a unique experience in the Minecraft scene."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our VoicePlugin"])}
    },
    "card3": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We optimize the game experience by using special mods like DynamX with realistic physics and vehicles, as well as a custom mod that integrates a unique overlay and mobile phone in the game."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mods"])}
    },
    "cards-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "game-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "games": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roleplay"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay is about playing fictional characters and experiencing entertaining and exciting situations through interactions with others."])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gungame"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In GunGame, the objective is to increase your level by killing enemies and fighting with new weapons until you achieve the final kill with a baseball bat."])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ttt"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In TTT, the Innocents must find and defeat the Traitors, while the Traitors try to remain undetected and kill everyone else."])}
      }
    ],
    "games-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our game modes"])},
    "social-media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
  "imprint": {
    "address": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["592 31 Křídla"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miloš Řezníček"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křídla 111"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "tmg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information according to §5 TMG"])}
    },
    "contact": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt", "@", "statemc.de"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])}
  },
  "join": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play along"])},
    "install-modpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install modpack"])},
    "install-modpack-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Install Java 8 (64-bit) if not available <br> 2. Install Technic Launcher if not available <br> 3. Search & download StateMC in the Technic Launcher <br> 4. Assign 3-6 GB of Ram to the modpack in the Technic Launcher (Launcher Options -> Java Settings) <br> 5. Now press Play and after everything is loaded click Join"])},
    "install-ts-plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Teamspeak Plugin"])},
    "install-ts-plugin-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Install Teamspeak 3 (64-bit), if not available <br> 2. Install TS3-addon <a href='/downloads' class='color-primary'>Downloads</a> <br> 3. Start Teamspeak and click on ts.statemc.de join <br> 4. Now you have to join the RP server in the lobby. In the chat you will now receive a code <br> 5. Send the received code to the \"StateMC\" chat on Teamspeak <br> 6. Join the channel \"Ingame-Warteraum\" & reconnect to the RP Server"])},
    "man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be able to play on StateMC you need our modpack. For voice-based game modes such as Roleplay or TTT, you also need our Teamspeak plugin. The installation is done quickly and gives you a unique gaming experience."])},
    "possible_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible errors"])},
    "possible_errors-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>I can only assign 1GB in the TechnicLauncher</b> <br> You need the Java 64-bit version. About the downloads: <a href='/downloads' class='color-primary'>Downloads</a> <br><br> <b>I can not install the TS3 plugin.</b> <br>You need the 64-bit version of Teamspeak. About the downloads: <a href='/downloads' class='color-primary'>Downloads</a> <br><br> <b>It says that I am already verified</b><br>If your Minecraft account is already linked to another Teamspeak identity, you can use the command '/unlink' in the lobby to remove it. After that, you can verify yourself again as usual"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to get on StateMC"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the video tutorial:"])},
    "video-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the video tutorial"])}
  },
  "mojangwarn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This server is not related to Mojang AB or Microsoft."])}
  },
  "multiplayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplayer"])},
  "pay-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to Payment"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
  "playercount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Players"])},
  "policy": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The use of our website is generally possible without providing personal data. As far as on our sides personal data (such as name, address or e-mail addresses) are collected, this is as far as possible on a voluntary basis. This data will not be disclosed to third parties without your express consent.<br /><br />We point out that data transmission over the Internet (eg communication by e-mail) security gaps. A complete protection of data against access by third parties is not possible.<br /><br />The use of contact data published within the framework of the imprint obligation by third parties to send unsolicited advertisements and information materials is hereby expressly excluded. The operators of the pages expressly reserve the right to take legal action in the event of unsolicited promotional information, such as spam e-mails."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])}
  },
  "provide-minecraft-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your Minecraft name:"])},
  "quickjoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
  "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searchTitles": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
    "playerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Name"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
  },
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "shop": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])}
  },
  "shopping-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD TO CART"])},
  "singleplayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singleplayer"])},
  "stats": {
    "community": {
      "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playtime on StateMC"])},
      "playTimePerPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing time per player"])},
      "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered players"])},
      "projectStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the start of the project"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Accounts"])}
    },
    "events": {
      "ATM_BLOWN_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM sprinkled"])},
      "DEATH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaths"])},
      "DRY_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drying cocaine"])},
      "DRY_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drying marijuana"])},
      "FARM_APPLES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apples picked"])},
      "FARM_COCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coca picked"])},
      "FARM_FISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fish caught"])},
      "FARM_IRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron degraded"])},
      "FARM_LETTUCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salad picked"])},
      "FARM_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marijuana picked"])},
      "FARM_MUSHROOMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mushrooms picked"])},
      "FARM_STRAWBERRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strawberries picked"])},
      "FARM_TREES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallen trees"])},
      "KARMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma"])},
      "KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kills"])},
      "LOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lose"])},
      "MONEY_EARNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money earned"])},
      "MONEY_SPENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spent money"])},
      "PACK_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packing cocaine"])},
      "PACK_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marijuana verpacken"])},
      "PROCESS_CARBON_TO_PROPANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon processed into propane"])},
      "PROCESS_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process cocaine"])},
      "PROCESS_IRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process iron"])},
      "PROCESS_WATER_TO_H2O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water processed to H2O"])},
      "PROCESS_WOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood processed"])},
      "PROCESS_WOOD_TO_CARBON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood processed into carbon"])},
      "TRASH_SEARCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trash can searched"])},
      "WIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wins"])},
      "WIN_CHALLENGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 1. Place"])},
      "WIN_CHALLENGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 2nd place"])},
      "WIN_CHALLENGE_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 3. Place"])},
      "WRONG_KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False kills"])}
    },
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "modes": {
      "freeroam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
      "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
      "gungame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])},
      "ttt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])}
    },
    "per-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per page"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players"])},
    "playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing time"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "times": {
      "ALL_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-Time"])},
      "DAILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "WEEKLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 5 today"])}
  },
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "terms": {
    "mc": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em class='color-secondary'>Last modified: 04.06.2021</em><br /><br />In order to provide you with the features of our network and to implement security measures against hackers and rule violations, we need your consent to collect and process the following data about your Minecraft profile (UUID):<br /><br />- IP addresses, logins, access logs<br />- Statistics in the games (kills / deaths etc), game time<br />- Game and chat behavior<br />- Settings / Bans / Mutes<br />- Link to Teamspeak<br /><br />We secure your data according to the state of the art and do not sell them to third parties. Statistics are publicly viewable for each account for the purpose of providing rankings.<br /><br />By clicking <strong class='color-primary'>Accept</strong> you agree to the storage and processing of data based on this statement.<br /><br />Furthermore, you agree to acknowledge and abide by the <a href='/rules'>server rules</a>."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft Server"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])},
    "ts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em class='color-secondary'>Last change: 04.06.2021</em><br /><br />To provide you with the features of our Teamspeak server and to implement security measures against hackers and rule violations, we need your consent to collect and process the following data about your TS identity:<br /><br />- IP addresses, logins, access logs, used platform/operating system<br />- Nickname / server and channel groups<br />- Banns / Kicks<br />- Voice<br /><br />For the operation of our Teamspeak server we use the official <a href='https://www.teamspeak.com/en/downloads/#server' target='_blank'>Teamspeak server software</a>. This processes sent voice content and chat messages for the purpose of using Teamspeak. More information can be found here.<br />We secure your data according to the state of the art and do not sell them to third parties.<br /><br />Your voice can and may be recorded and published in the channel marked with '\" Ingame'.<br /><br />By using the StateMC.de Teamspeak you agree to the processing of data based on the above statement.<br /><br />Furthermore, you agree to take note of and comply with the <a href='/rules'>Teamspeak rules</a>."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak Server"])}
    }
  },
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "units": {
    "DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])}
  },
  "validation": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be accepted"])},
    "code-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consists of 5 characters"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
    "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must contain a number or special character"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords do not match"])},
    "password-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters"])},
    "password-upper-and-lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one upper and one lower case letter"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validation.uuid"])}
  },
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])}
}