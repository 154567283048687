export default {
  "404": {
    "back-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angeforderte Seite konnte nicht gefunden werden!"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])}
  },
  "account": {
    "last-logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Logins"])},
    "last-logins-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "last-logins-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "links": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlinkungen"])},
      "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlinkung löschen"])},
      "unlink-confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Verlinkung wirklich löschen?"])},
      "unlink-confirm-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlinkung löschen"])}
    },
    "my-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Stats"])},
    "player-verify": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt verknüpfen"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "no-player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast dein Account noch nicht mit deinem Minecraft Account verknüpft"])},
      "player-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielername"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib im Minecraft den Befehl <code class='color-primary'>/account-verify</code> ein um einen Verifizierungs-Code zu generieren. Gib anschliessend dein Minecraft Namen und den generierten Code hier ein."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft Account verknüpfen"])}
    },
    "rank-expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang gültig bis"])},
    "register-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
    "state": {
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerhafter Login"])},
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeloggt"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Account"])}
  },
  "acp": {
    "licenses": {
      "AIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluglizenz"])},
      "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein"])},
      "WEAPON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffenlizenz"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz hinzufügen"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])}
    }
  },
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "admin": {
    "characters": {
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
      "delete": {
        "success": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Charakter wurde gelöscht & die Löschung notiert"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
        }
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "idNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Nummer"])},
      "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt Gespielt"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktername"])},
      "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
      "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler ID"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktere"])}
    },
    "chats": {
      "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließzeit"])},
      "closedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließer"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
      "selectChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Char auswählen"])},
      "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absender"])}
    },
    "download": {
      "delete-download-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchstest du diesen Download wirklich löschen?"])},
      "delete-download-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download löschen"])},
      "delete-section-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Download-Abschnitt wirklich löschen? Es werden dabei auch alle Downloads gelöscht!"])},
      "delete-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Abschnitt löschen"])}
    },
    "permissions": {
      "addPermSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung wurde erfolgreich hinzugrfügt"])},
      "groups": {
        "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gruppe erstellen"])},
        "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchstest du die Grupppe wirklich löschen?"])},
        "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
        "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])}
      },
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Berechtigung"])},
      "ranks": {
        "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang bearbeiten"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
        "mcPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft - Prefix"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ränge"])},
        "titlesingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])}
    },
    "player": {
      "ban": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban anlegen"])}
        },
        "delete": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Ban wirklich löschen?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban löschen"])}
        },
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban bearbeiten"])}
        },
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
        "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])}
      },
      "char-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktermöglichkeit"])},
      "characters": {
        "headers": {
          "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
          "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
          "id-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Nummer"])},
          "idNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Nummer"])},
          "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt Gespielt"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
          "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitet?"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakter"])}
      },
      "mod-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Benutzung"])},
      "mod-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ModID"])},
      "mod-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Benutzung"])},
      "mod-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod Name"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
      "notification": {
        "error": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler ist wahrscheinlich nicht online"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
        },
        "success": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler wurde erfolgreich notified"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
        },
        "timeout": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Verbindung zum MC Server möglich"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])}
        }
      },
      "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
      "warnings": {
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnung anlegen"])}
        },
        "delete": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Verwarnung wirklich löschen?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnung löschen"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnung bearbeiten"])}
        },
        "headers": {
          "proof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beweise"])},
          "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
          "warned-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnt am"])},
          "warner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnt von"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnungen"])}
      }
    },
    "players": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielername"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler"])},
      "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])}
    },
    "pre-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - "])},
    "property": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "rentable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietbar"])}
    },
    "unban": {
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler ist nicht gebannt"])},
      "btn": {
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban löschen"])},
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban verkürzen"])}
      },
      "success": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler wurde erfolgreich entbannt"])},
        "text-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ban wurde erfolgreich verkürzt"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban aufheben"])}
    },
    "vehicle": {
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
      "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "licensePlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitzer"])},
      "vehicleColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugfarbe"])},
      "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell"])}
    }
  },
  "api-errors": {
    "AIRPORT_BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast einen Einreiseban, melde dich bitte in unserem Teamspeak Support"])},
    "CHAT_ALREADY_OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast bereits einen solchen Chat offen!"])},
    "CHAT_TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast für diesen Chat aktuell eine Sperre!"])},
    "CONFIRMATION_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Code ist bereits abgelaufen. Du erhälst einen neuen Code."])},
    "CONFIRMATION_TOO_MUCH_FAILURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu viele Fehlversuche. Du erhälst einen neuen Code."])},
    "CONFIRMATION_WRONG_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Code"])},
    "EMAIL_ALREADY_REGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail Adresse wird bereits verwendet"])},
    "EMAIL_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail Adresse"])},
    "INVALID_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Code"])},
    "INVALID_LICENSE_FOR_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Lizenz für Lizenztyp"])},
    "INVALID_LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail und Passwort stimmen nicht überein"])},
    "MISSING_FIELD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte fülle alle Felder aus"])},
    "NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigung"])},
    "PLAYER_HAS_ALREADY_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler hat diese Berechtigung bereits (möglicherweise durch eine Gruppe oder den Rang)"])},
    "QUIZ_BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Quiz nicht bestanden, hierfür ist kein Entbannungsantrag nötig"])}
  },
  "auth": {
    "accept-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die <a class='color-primary' target='_blank' href='/terms'>AGB's</a>"])},
    "already-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast bereits ein Account?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration abschliessen"])},
    "confirm-already-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine E-Mail Adresse wurde noch nicht verifiziert. Gib den Code ein um die Registration abzuschliessen"])},
    "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du solltest in kürzester Zeit eine E-Mail mit einem Bestätigungs-Code erhalten. Gib den Code ein um die Registration abzuschliessen. (Vergiss nicht deinen Spam Ordner zu überprüfen)"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ein Account"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gast"])},
    "labels": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
      "password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib dein Passwort ein"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch kein Account?"])},
    "resend-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail erneut senden"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "reset-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du solltest in kürzester Zeit eine E-Mail mit einem Code erhalten. Gib den Code ein um ein neues Passwort zu setzen. (Vergiss nicht deinen Spam Ordner zu überprüfen)"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "chat": {
    "APPLY_BUILDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Builderbewerbung"])},
    "APPLY_CONTENT_SOCIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content (Sparte SocialMedia)"])},
    "APPLY_CONTENT_TEXTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content (Sparte Texturen)"])},
    "APPLY_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developerbewerbung"])},
    "APPLY_SUPPORTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporterbewerbung"])},
    "CHAR_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charlöschung"])},
    "SECCHAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweitchar"])},
    "UNBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entbannung"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Chat eröffnen"])},
    "newOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein neuer Chat wurde geöffnet! 🎉"])},
    "newOpenInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen erwarten dich nach öffnen des Chats"])},
    "noChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch keine Chats!"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "premessage": {
      "autoparameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Parameter"])},
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht überprüfen"])},
      "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtparameter"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamler"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichttext"])}
    },
    "premessages": {
      "SECCHAR_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweitchar akzeptieren"])},
      "SECCHAR_DENY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweitchar ablehnen"])},
      "SECCHAR_RECIEVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweitchar erhalten"])},
      "UNBAN_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entbannungs akzeptieren"])},
      "UNBAN_DENY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entbannung ablehnen"])},
      "UNBAN_RECIEVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entbannung erhalten"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
    },
    "reasons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierten Grund hinzufügen"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
      "secchar": {
        "notEnoughEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzureichender Einreisegrund"])},
        "recentBan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzlicher Bann aus unserem Server"])},
        "storyUndetailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschichte zu undetailiert"])},
        "storyfoggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unstimmigkeit innerhalb der Geschichte"])},
        "toLessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu wenig Infos über deinen Charakter"])}
      },
      "unban": {
        "notEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzureichender Entbannungsantrag"])},
        "notRPfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sehen kein Grund, wie du unser RP fördern könntest"])},
        "toLessTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu wenig Zeit zwischen Ban und Antrag"])}
      }
    },
    "side": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
      "precreated": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fertignachrichten"]), _normalize(["Fertignachricht"])])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung"])}
    },
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespräch"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Antrag"]), _normalize(["Anträge"]), _normalize(["Deine Anträge"])])},
    "welcome": {
      "APPLY_BUILDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Bitte geb uns noch folgende Informationen: </strong> <ul class=\"ml-7\"> <li>Dein Name und Alter</li> <li>Warum StateMC</li> <li>Deine Erfahrung als Builder</li> <li>Was kannst du gut bauen</li> <li>Deine Erfahrung mit Modblöcken</li> <li>Deine Erfahrung mit RP und StateMC</li> </ul>"])},
      "APPLY_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Bitte geb uns noch folgende Informationen:</strong> <ul class=\"ml-7\"><li>Dein Name und Alter</li><li>Warum StateMC</li><li>Wie du uns helfen willst</li><li>Deine Kenntnisse</li><li>Sowie deine Fähigkeiten im Umgang mit Git und bspw. GitLab</li></ul>"])},
      "APPLY_SUPPORTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Bitte geb uns noch folgende Informationen: </strong> <ul class=\"ml-7\"> <li>Dein Name und Alter</li> <li>Warum StateMC</li> <li>Deine Erfahrung als Supporter</li> <li>Deine Erfahrung mit RP und StateMC</li> </ul>"])},
      "CHAR_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Bitte geb uns noch folgende Informationen: </strong> <ul class=\"ml-7\"> <li>Der Grund der Charakterlöschung</li> <li>Dein Plan für deine Zukunft auf StateMC</li> </ul>"])},
      "SECCHAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Bitte geb uns noch folgende Informationen: </strong> <ul class=\"ml-7\"> <li>Deine Spielzeit</li> <li>Den Einreisegrund deines Charakters</li> <li>Die Geschichte deines Charakters</li> <li>Die Eigenschaften deines Charakters</li> </ul>"])},
      "UNBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Bitte geb uns noch folgende Informationen: </strong> <ul class=\"ml-7\"> <li>Dein Bangrund</li> <li>Deine Bandauer</li> <li>Den Grund weshalb wir dich entbannen sollten</li> </ul> "])}
    }
  },
  "cookies": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten über dich, wie deine IP-Adresse. Wir teilen diese Daten auch mit Dritten. Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis eines berechtigten Interesses erfolgen."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Website verwendet Cookies"])}
  },
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "dialog": {
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
  },
  "downloads": {
    "download-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "open-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "games": {
    "freeroam": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Freeroam hat jeder Spieler die Freiheit, seine eigenen Aktivitäten und Ziele zu wählen. Man kann sich mit anderen Spielern in Autorennen messen, wild durch die Gegend schießen, farmen, oder an den regelmäßigen, spannenden Herausforderungen teilnehmen. Es gibt keine festen Regeln oder Zwänge und die Möglichkeiten sind unbegrenzt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])}
    },
    "gungame": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In GunGame geht es darum, sich in einem Wettbewerb mit anderen Spielern durch das Töten von Gegnern zu verbessern. Nach jedem erfolgreichen Kill erhält man eine neue Waffe, was zu immer größeren Herausforderungen führt. Das Ziel des Spiels ist es, durch das Meistern dieser Herausforderungen bis zum höchsten Level aufzusteigen und den finalen Kill mit einem Baseballschläger auszuführen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])}
    },
    "roleplay": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Roleplay geht es darum, sich in die Rolle eines fiktiven Charakters zu versetzen und dessen Leben zu simulieren. Dabei kann es sich um verschiedene Arten von Charakteren handeln wie zum Beispiel einen Gangster, Polizisten oder Geschäftsmann. Ziel ist es, unterhaltsame und spannende Situationen zwischen den verschiedenen Charakteren zu erschaffen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])}
    },
    "ttt": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In TTT werden die Spieler in drei verschiedene Gruppen eingeteilt: Traitor, Detectives und Innocents. Die Aufgabe der Innocents ist es, die Traitor aufzuspüren und zu eliminieren, während die Detectives ihnen durch ihre speziellen Fähigkeiten unterstützend zur Seite stehen. Auf der anderen Seite haben die Traitor das Ziel, möglichst unbemerkt alle Innocents und Detectives zu töten, bevor ihre wahre Identität aufgedeckt wird."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])}
    }
  },
  "home": {
    "card1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC unterscheidet sich von Vanilla-Servern, indem wir innovative Mods einsetzen, um das Spielerlebnis zu erweitern und einzigartige Erfahrungen zu bieten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über StateMC"])}
    },
    "card2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser eigens entwickeltes Voice-Plugin für Teamspeak ermöglicht eine hervorragende Audioqualität im Spielerlebnis und wird in Roleplay, Freeroam und TTT eingesetzt um ein einzigartiges Erlebnis in der Minecraft-Szene zu schaffen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser VoicePlugin"])}
    },
    "card3": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir optimieren das Spielerlebnis durch den Einsatz von speziellen Mods wie DynamX mit realistischer Physik und Fahrzeugen sowie einer eigenen Mod, die ein einzigartiges Overlay und Handy im Spiel integriert."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Mods"])}
    },
    "cards-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
    "game-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
    "games": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roleplay"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Roleplay geht es darum, fiktive Charaktere zu spielen und durch Interaktionen mit anderen unterhaltsame und spannende Situationen zu erleben."])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gungame"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In GunGame geht es darum, durch das Töten von Gegnern das eigene Level zu erhöhen und mit immer neuen Waffen zu kämpfen, bis man den finalen Kill mit einem Baseballschläger erreicht."])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ttt"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In TTT müssen die Innocents die Traitor finden und besiegen, während die Traitor versuchen, unentdeckt zu bleiben und alle anderen zu töten."])}
      }
    ],
    "games-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Spielmodis"])},
    "social-media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Team"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
  "imprint": {
    "address": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["592 31 Křídla"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschechien"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miloš Řezníček"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křídla 111"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "tmg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben gemäß §5 TMG"])}
    },
    "contact": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt", "@", "statemc.de"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])}
  },
  "join": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitspielen"])},
    "install-modpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack installieren"])},
    "install-modpack-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Java 8 (64-Bit) installieren, falls nicht vorhanden <br> 2. Technic Launcher installieren, falls nicht vorhanden <br> 3. StateMC im Technic Launcher suchen & herunterladen <br> 4. Im Technic Launcher dem Modpack 3-6 GB Ram zuweisen (Launcher Optionen -> Java Einstellungen) <br> 5. Drücke nun auf Spielen und nachdem alles geladen ist auf Beitreten"])},
    "install-ts-plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak Plugin installieren"])},
    "install-ts-plugin-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Teamspeak 3 (64-Bit) installieren, falls nicht vorhanden <br> 2. TS³-Addon installieren <a href='/downloads' class='color-primary'>Downloads</a> <br> 3. Teamspeak starten und auf ts.statemc.de joinen <br> 4. Nun musst du in der Lobby auf den RP Server joinen. Im Chat erhälst du nun einen Code <br> 5. Den erhaltenen Code in den \"StateMC\"-Chat auf Teamspeak schicken <br> 6. Dem Channel \"Ingame-Warteraum\" joinen & erneut auf den RP Server verbinden"])},
    "man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um auf StateMC spielen zu können benötigst du unser Modpack. Für Voicebasierte Spielmodis wie Roleplay oder TTT braucht man zusätzlich unser Teamspeak Plugin. Die Installation ist schnell erledigt und ermöglicht dir ein einzigartiges Spielerlebnis."])},
    "possible_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mögliche Fehler"])},
    "possible_errors-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Ich kann im TechnicLauncher nur 1GB zuweisen</b> <br> Du brauchst die Java 64-Bit Version. Zu den Downloads: <a href='/downloads' class='color-primary'>Downloads</a> <br><br> <b>Ich kann das TS³-Plugin nicht installieren.</b> <br>Du benötigst die 64-Bit Version von Teamspeak. Zu den Downloads: <a href='/downloads' class='color-primary'>Downloads</a> <br><br> <b>Bei mir steht, dass ich schon verifiziert bin</b><br> Wenn dein Minecraft Account bereits mit einer anderen Teamspeak Identität verknüpft ist, kannst du in der Lobby den Command '/unlink' benutzen um diese zu entfernen. Danach kannst du dich wie gewohnt erneut verifizieren"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So kommst du auf StateMC"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Video Tutorial:"])},
    "video-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Video Tutorial"])}
  },
  "mojangwarn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Server steht in keinem Zusammenhang zu Mojang AB oder Microsoft."])}
  },
  "multiplayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrspieler"])},
  "pay-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zur Zahlung"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler"])},
  "playercount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Spieler"])},
  "policy": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br /><br />Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br /><br />Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])}
  },
  "provide-minecraft-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib deinen Minecraft-Namen an:"])},
  "quickjoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitreten"])},
  "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gründe"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "searchTitles": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler ID"])},
    "playerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielername"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
  },
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "shop": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])}
  },
  "shopping-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN DEN WARENKORB"])},
  "singleplayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelspieler"])},
  "stats": {
    "community": {
      "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit auf StateMC"])},
      "playTimePerPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit pro Spieler"])},
      "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierte Spieler"])},
      "projectStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seit dem Start des Projektes"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierte Accounts"])}
    },
    "events": {
      "ATM_BLOWN_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM gesprengt"])},
      "DEATH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaths"])},
      "DRY_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokain trocknen"])},
      "DRY_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marihuana trocknen"])},
      "FARM_APPLES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äpfel gepflückt"])},
      "FARM_COCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koka gepflückt"])},
      "FARM_FISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fische geangelt"])},
      "FARM_IRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eisen abgebaut"])},
      "FARM_LETTUCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salat gepflückt"])},
      "FARM_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marihuana gepflückt"])},
      "FARM_MUSHROOMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilze gepflückt"])},
      "FARM_STRAWBERRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdbeeren gepflückt"])},
      "FARM_TREES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefällte Bäume"])},
      "KARMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma"])},
      "KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kills"])},
      "LOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lose"])},
      "MONEY_EARNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geld verdient"])},
      "MONEY_SPENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgegebenes Geld"])},
      "PACK_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokain verpacken"])},
      "PACK_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marihuana verpacken"])},
      "PROCESS_CARBON_TO_PROPANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohlenstoff zu Propan verarbeitet"])},
      "PROCESS_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokain verarbeiten"])},
      "PROCESS_IRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eisen verarbeiten"])},
      "PROCESS_WATER_TO_H2O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser zu H2O verarbeitet"])},
      "PROCESS_WOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holz verabeitet"])},
      "PROCESS_WOOD_TO_CARBON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holz zu Kohlenstoff verarbeitet"])},
      "TRASH_SEARCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mülleimer durchsucht"])},
      "WIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wins"])},
      "WIN_CHALLENGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 1. Platz"])},
      "WIN_CHALLENGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 2. Platz"])},
      "WIN_CHALLENGE_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 3. Platz"])},
      "WRONG_KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Kills"])}
    },
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "modes": {
      "freeroam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
      "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
      "gungame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])},
      "ttt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])}
    },
    "per-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Seite"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler"])},
    "playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
    "times": {
      "ALL_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-Time"])},
      "DAILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "WEEKLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 5 Heute"])}
  },
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "terms": {
    "mc": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em class='color-secondary'>Letzte Änderung: 04.06.2021</em><br /><br />Um dir die Funktionen unseres Netzwerks bereitstellen zu können und Sicherheitsmaßnahmen gegen Hacker und Regelverstöße durchführen zu können benötigen wir dein Einverständnis folgende Daten zu deinem Minecraft Profil (UUID) erheben und verarbeiten zu können:<br /><br />• IP Adressen, Logins, Zugriffslogs<br />• Statistiken in den Spielen (Kills / Deaths etc), Spielzeit<br />• Spiel- und Chatverhalten<br />• Einstellungen / Bans / Mutes<br />• Verknüpfung mit Teamspeak<br /><br />Wir sichern deine Daten nach dem Stand der Technik und verkaufen diese nicht an Dritte. Statistiken sind öffentlich zu jedem Account einsehbar zum Zwecke der Bereitstellung von Ranglisten.<br /><br />Mit dem Klick auf <strong class='color-primary'>Annehmen</strong> stimmst du der Speicherung und Verarbeitung der Daten auf Basis dieser Erklärung zu.<br /><br />Weiterhin stimmst du zu, die <a href='/rules'>Serverregeln</a> zur Kenntnis zu nehmen und einzuhalten. "])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft Server"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "ts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em class='color-secondary'>Letzte Änderung: 04.06.2021</em><br /><br />Um dir die Funktionen unseres Teamspeak-Servers bereitstellen zu können und Sicherheitsmaßnahmen gegen Hacker und Regelverstöße durchführen zu können benötigen wir dein Einverständnis folgende Daten zu deiner TS-Identität erheben und verarbeiten zu können:<br /><br />• IP Adressen, Logins, Zugriffslogs, verwendete Plattform/Betriebssystem<br />• Nickname / Server- und Channelgruppen<br />• Banns / Kicks<br />• Stimme<br /><br />Zum Betrieb unseren Teamspeak Servers verwenden wir die offizielle <a href='https://www.teamspeak.com/en/downloads/#server' target='_blank'>Teamspeak Server Software</a>. Diese verarbeitet gesendete Sprachinhalte und Chatnachrichten im Sinne des Nutzungszwecks von Teamspeak. Weitere Informationen sind hier zu finden.<br />Wir sichern deine Daten nach dem Stand der Technik und verkaufen diese nicht an Dritte.<br /><br />Deine Stimme kann und darf in den mit '» Ingame' gekennzeichneten Channel aufgezeichnet und veröffentlicht werden.<br /><br />Mit dem Verwenden des StateMC.de Teamspeak stimmst du der Verarbeitung der Daten auf Basis der obigen Erklärung zu.<br /><br />Weiterhin stimmst du zu, die <a href='/rules'>Teamspeakregeln</a> zur Kenntnis zu nehmen und einzuhalten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak Server"])}
    }
  },
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
  "units": {
    "DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])}
  },
  "validation": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss akzeptiert werden"])},
    "code-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteht aus 5 Zeichen"])},
    "duration-ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf maximal 5000 Tage betragen"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail Adresse"])},
    "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Zahl oder Spezialzeichen enthalten"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Zahl sein"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
    "password-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 8 Zeichen"])},
    "password-upper-and-lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Gross- und ein Kleinbuchstabe"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld wird benötigt"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst eine UUID angeben"])}
  },
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])}
}